import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import React from "react";

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>404 | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        className={'padding-page-top'}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <h1>404 - Tento vlak ujel</h1>
                //TODO: Tohle

            </motion.div>
        </>
    );
}