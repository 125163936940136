import {Helmet} from 'react-helmet';
import {motion} from "framer-motion";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Capsule from "../komponenty/blog/Capsule";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {supabase} from "../komponenty/utils/supabase";

function Index() {

    const [akce, setAkce] = useState([]);
    const [aktuality, setAktuality] = useState([]);


    useEffect(() => {
        void getAkce();
        void getAktuality();
    }, []);

    async function getAkce() {
        const { data } = await supabase
            .from("akce")
            .select()
            .order('id', { ascending: false })
            .limit(1);

        setAkce(data);
    }

    async function getAktuality() {
        const { data } = await supabase
            .from("aktuality")
            .select()
            .order('id', { ascending: false })
            .limit(1);

        setAktuality(data);
    }


    return (
        <>
            <Helmet>
                <title>Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div id={"content-container"}>
                    <div className={'video-div center-vertical'}>
                        <video autoPlay={true} muted={true} loop={true}>
                            <source src="/vid/vid.webm" type="video/mp4" />
                        </video>
                        <img src={'/img/logoShadow.webp'}
                             alt={'Logo Posázavského Pacifiku'}
                        />
                        <a href="#info">
                            <div className="mouse-scroll">
                                <div className="mouse-icon">
                                    <div className="mouse-wheel"></div>
                                </div>
                                <div>
                                    <motion.span className="arrow mouse-down-arrow-1"
                                                 transition={{ repeat: Infinity, duration: 1, delay: 0.1 }}
                                                 animate={{opacity: [0,1,0] }}>
                                    </motion.span>
                                    <motion.span className={"arrow"}
                                                 transition={{ repeat: Infinity, duration: 1, delay: 0.2 }}
                                                 animate={{opacity: [0,1,0] }}>
                                    </motion.span>
                                    <motion.span className={"arrow"}
                                                 transition={{ repeat: Infinity, duration: 1, delay: 0.3 }}
                                                 animate={{opacity: [0,1,0] }}>
                                    </motion.span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className={"kdo-jsme-main"}>
                        <div id="info" className={"kdo-jsme-inside"}>
                            <div className={"kdo-jsme-deco-left"}>
                            </div>
                            <div className={"kdo-jsme-content"}>
                                <h1 className={"text-header"}>Kdo Jsme?</h1>
                                <div className={'flex-column center-vertical center-horizontal'}>
                                    <p>
                                        <b className={'text-uppercase'}>Posázavský Pacifik</b> <br /><br />
                                        …dávno zažitý pojem pro železniční trať podél řeky Sázavy. <br /><br />
                                        …pojem vlastní všem trampům, vodákům, turistům, romantikům. <br /><br />
                                        …a pojem, jež si s úctou propůjčuje náš spolek - <b>Parta přátel z Posázaví, pečující o historická železniční vozidla.</b> <br />
                                    </p>
                                    <Link to={'/kontakt'} className={"text-white button-primary"}>
                                        <span>Chcete svůj vlak?</span>
                                        <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                                    </Link>
                                </div>
                            </div>
                            <div className={"kdo-jsme-deco-right"}>
                            </div>
                        </div>
                    </div>
                    <div className={"akce-section-main"}>
                        <div className={"scroll-text-main"}>

                        </div>
                        <div className={"akce-section-content"}>
                            <h1 className={"text-white text-header text-center"}>Novinky</h1>
                            {aktuality.map((preview, i) => (
                                <Capsule deco={true} decoPos={"left"} data={preview} type={1} />
                            ))}
                            <Link to={'/aktuality'} className={"text-white button-primary"}>
                                <span>Více aktualit</span>
                                <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                            </Link>
                            {akce.map((preview, i) => (
                                <Capsule deco={true} decoPos={"right"} data={preview} />
                            ))}
                            <Link to={'/akce'} className={"text-white button-primary"}>
                                <span>Více akcí</span>
                                <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                            </Link>
                        </div>
                    </div>
                    <div className={'stroje-index-main flex-column center-vertical'}>
                        <div className={'stroje-index-content'}>
                            <div className={'stroje-index-trains flex-column center-horizontal justify-c-space-evenly'}>
                                <div className={'flex-column'}>
                                    <img alt={'Lokomotiva T478.2006'} src={'/img/zamracena.webp'}/>
                                    <p className={'text-right text-uppercase'}>Lokomotiva T478.2006</p>
                                </div>
                                <div className={'flex-column'}>
                                    <img alt={'Lokomotivy T435.0'} src={'/img/hektor.webp'}/>
                                    <p className={'text-right text-uppercase'}>Lokomotivy T435.0</p>
                                </div>
                                <div className={'flex-column'}>
                                    <img alt={'Lokomotiva T211.0'} src={'/img/uhhh.webp'}/>
                                    <p className={'text-right text-uppercase'}>Lokomotiva T211.0</p>
                                </div>
                            </div>
                            <div className={'stroje-index-text flex-column center-horizontal center-vertical'}>
                                <h1 className={"text-header text-center"}>Naše stroje</h1>
                                <p  className={'text-justify'}>Naše lokomotivy představují průřez motorovou trakcí Československých státních drach 60. let. Jedná se o období nástupu prvních generací motorových lokomotiv, a to s přímou vazbou na tratě Posázavského pacifiku.
                                </p>
                                <div className={'flex-column stroje-index-lokomotiva-mobil'}>
                                    <img alt={'Lokomotiva T478.2006'} src={'/img/zamracena.webp'}/>
                                    <p className={'text-center text-uppercase'}>Lokomotiva T478.2006</p>
                                </div>
                                <p  className={'text-justify'}>V naší sbírce se nacházejí jak zástupci traťových lokomitv motorové trakce, konkrétně zamračená (T 478.2006) a hektoři (řada T 435.0) inventární čísla 003, 0108 a 0114 tak i motorové lokomotivy z vleček, konkrétně hektor T 435.0509 a malé vlečkové lokomotivy řady T 211.0 a T 334.0.
                                </p>
                                <div className={'flex-column stroje-index-lokomotiva-mobil'}>
                                    <img alt={'Lokomotivy T435.0'} src={'/img/hektor.webp'}/>
                                    <p className={'text-center text-uppercase'}>Lokomotivy T435.0</p>
                                </div>
                                <p  className={'text-justify'}>Zástupcem motorových vozů typických pro tratě Posázavského pacifiku je pak "dvěstědvaašedesátka", konkrétně pak motorový vůz M 262.045.</p>
                                <Link to={'/o-nas'} className={"text-white button-primary"}>
                                    <span>Další stroje</span>
                                    <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={'jizda-section-main flex-column center-vertical center-horizontal'}>
                        <div className={'flex-column center-vertical'}>

                            <h1  className={'text-header text-center jizda-section-h1 text-white'}>Máte zájem o jízdu?</h1>
                            <p  className={'text-center jizda-section-p text-uppercase text-white'}>Tak se podívejte na naše nadcházející akce a zarezervujte si místo!</p>
                            <Link to={'/akce'} className={"text-white button-primary"}>
                                <span>Všechny akce</span>
                                <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                            </Link>

                        </div>
                        <div className={'jizda-section-divider flex-row center-vertical'}>
                            <span/>
                            <p  className={'text-center text-uppercase'}>Nebo</p>
                            <span/>
                        </div>
                        <div className={'flex-column center-vertical'}>
                            <h1  className={'text-header text-center jizda-section-h1 text-white'}>Kontaktujte nás</h1>
                            <p  className={'text-center jizda-section-p text-uppercase text-white'}>A domluvme se na speciální akci!</p>
                            <Link to={'/kontakt'} className={"text-white button-primary"}>
                                <span>Kontakt</span>
                                <span><FontAwesomeIcon icon={faAngleDoubleRight} /></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
}

export default Index;