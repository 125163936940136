import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import {supabase} from "../../komponenty/utils/supabase";


export default function TestSelector() {

    const [x, setX] = useState(18);
    const [y, setY] = useState(6);
    const [trainMap, setTrainMap] = useState([]);

    useEffect(() => {
        makeTrainMap();
        console.log("og")
    }, [x, y]);

    function makeTrainMap() {
        setTrainMap((prevMap) => {
            let newMap = [];
            for (let i = 0; i < y; i++) {
                newMap.push([]);
                for (let j = 0; j < x; j++) {
                    newMap[i].push(prevMap[i] && prevMap[i][j] !== undefined ? prevMap[i][j] : 1);
                }
            }
            return newMap;
        });
    }

    async function updateTrainMap(cell, state) {
        let pos = cell.split("_");
        trainMap[pos[0]][pos[1]] = state;
    }

    function countSeats() {
        return trainMap.flatMap(subArray => subArray).filter(element => element === 1).length;
    }

    async function save() {
        console.log("Počet míst: " + countSeats());
        console.log("Velikost: " + x * y);
        console.log(trainMap)

    }

    return (
        <>
            <Helmet>
                <title>Test | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
                <div className={"padding-page-top margin-m gap-s flex-column"}>
                    <div className={"flex-row gap-s"}>
                        <p>Počet řad</p>
                        <input type={'number'} placeholder={'Počet řad'} value={x} onChange={(e) => {
                            setX(e.target.value);
                        }}/>
                        <p>Počet sedadel</p>
                        <input type={'number'} placeholder={'Počet sedadel'} value={y} onChange={(e) => {
                            setY(e.target.value);
                        }}/>
                    </div>
                    <div className={'flex-column gap-s'}>
                        {
                            trainMap.map((row, i) => (
                                <div className={"flex-row gap-s"}>
                                    {row.map((cell, j) => (
                                        <input type={"checkbox"} key={j} className={'spesl'} defaultChecked={cell}
                                               onClick={(e) => {
                                                   updateTrainMap(i + '_' + j, Number(e.target.checked));
                                               }}/>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                    <p className={'admin-dash-button text-center'} onClick={() => {
                        save();
                    }}>
                        Uložit
                    </p>
                </div>
            </motion.div>
        </>
    )
}