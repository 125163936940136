import {Route, Routes, useLocation} from "react-router-dom";
import Index from "../../stranky";
import Akce from "../../stranky/akce";
import AkceClanek from "../../stranky/akce_clanek";
import ONas from "../../stranky/o-nas";
import Kontakt from "../../stranky/kontakt";
import React from "react";
import {AnimatePresence} from "framer-motion";
import Stroje from "../../stranky/stroje";
import Admin from "../../stranky/admin";
import Login from "../../stranky/admin/login";
import NovaAkce from "../../stranky/admin/nova_akce";
import SpravaAkce from "../../stranky/admin/sprava_akce";
import {AuthRoute} from "./authRoute";
import NovyStroj from "../../stranky/admin/novy_stroj";
import SpravaStroje from "../../stranky/admin/sprava_stroje";
import Aktuality from "../../stranky/aktuality";
import NovaAktualita from "../../stranky/admin/nova_aktualita";
import SpravaAktualit from "../../stranky/admin/sprava_aktuality";
import AktualitaClanek from "../../stranky/aktualitaClanek";
import NahratObrazek from "../../stranky/admin/nahrat_obrazek";
import SpravaObrazky from "../../stranky/admin/sprava_obrazky";
import RezervaceMain from "../../stranky/rezervaceMain";
import NotFound from "../../stranky/404";
import SpravaRezervace from "../../stranky/admin/sprava_rezervace";
import UpravitRezervace from "../../stranky/admin/upravit_rezervace";
import TestSelector from "../../stranky/admin/test";

function AnimatedRoutes() {

    const loc = useLocation();

    return (
        <AnimatePresence initial={false} mode={'wait'}>
            <Routes location={loc} key={loc.pathname}>
                <Route path={"/"} element={<Index />} />
                <Route path={"/akce"}>
                    <Route index element={<Akce />} />
                    <Route path={":akce_id"} element={<AkceClanek />} />
                </Route>
                <Route path={"/aktuality"}>
                    <Route index element={<Aktuality />} />
                    <Route path={":aktualita_id"} element={<AktualitaClanek />} />
                </Route>
                <Route path={"/rezervace/:rezervace_id"} element={<RezervaceMain />} />
                <Route path={"/o-nas"}>
                    <Route index element={<ONas />} />
                    <Route path={'stroje'} element={<Stroje />} />
                </Route>
                <Route path={"/kontakt"} element={<Kontakt />} />

                <Route path={'/admin'}>
                    <Route index element={<AuthRoute><Admin /></AuthRoute>} />
                    <Route path={'login'} element={<AuthRoute login={true}><Login /></AuthRoute>} />
                    <Route path={'akce'}>
                        <Route index element={<AuthRoute><SpravaAkce /></AuthRoute>} />
                        <Route path={'nova'} element={<AuthRoute><NovaAkce /></AuthRoute>} />
                    </Route>
                    <Route path={'aktuality'}>
                        <Route index element={<AuthRoute><SpravaAktualit /></AuthRoute>} />
                        <Route path={'nova'} element={<AuthRoute><NovaAktualita /></AuthRoute>} />
                    </Route>
                    <Route path={'stroje'}>
                        <Route index element={<AuthRoute><SpravaStroje /></AuthRoute>} />
                        <Route path={'novy'} element={<AuthRoute><NovyStroj /></AuthRoute>} />
                    </Route>
                    <Route path={'obrazky'}>
                        <Route index element={<AuthRoute><SpravaObrazky /></AuthRoute>} />
                        <Route path={'nahrat'} element={<AuthRoute><NahratObrazek /></AuthRoute>} />
                    </Route>
                    <Route path={'rezervace'}>
                        <Route index element={<AuthRoute><SpravaRezervace /></AuthRoute>} />
                        <Route path={'test'} element={<AuthRoute><TestSelector /></AuthRoute>} />
                        <Route path={'upravit/:rezervace_id'} element={<AuthRoute><UpravitRezervace /></AuthRoute>} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    );

}

export default AnimatedRoutes;