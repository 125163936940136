import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";

export default function AdminRezervace({rez}) {


    const navigate = useNavigate();
    const [lokName, setLokName] = useState('Načítání...');
    let daToast;

    useEffect(() => {
            getLokomotivaName(rez.locomotive_id);
        }, []);

    async function askToDelete() {
        daToast = toast((t) => (
            <span className={'flex-column center-vertical'}>
                Opravdu chcete smazat rezervace pro akci
                <span><b>{rez.name}</b>?</span>
                <div className={'flex-row center-horizontal margin-s'}>
                    <p className={'admin-dash-button'} onClick={deleteRez}>
                        Ano
                    </p>
                    <p className={'admin-dash-button'} onClick={() => toast.dismiss(t.id)}>
                        Ne
                    </p>
                </div>
            </span>
        ), {
            duration: 8000,
        });
    }

    async function deleteRez() {
        const { error } = await supabase
            .from('rezervace_system')
            .delete()
            .eq('id', rez.id)

        if (error) {
            toast.error('Možnost rezervace nebyla smazána', {
                id: daToast,
            });
            console.log(error);
        } else {
            disassociateAkce();
        }
    }

    async function disassociateAkce() {
        const { error } = await supabase
            .from('akce')
            .update({ reservable: 0 })
            .eq('reservable', rez.id)

        if (error) {
            toast.error('Odpojení od akce selhalo', {
                id: daToast,
            })
            console.log(error);
        } else {
            toast.success('Úspěšně odstraněno!', {
                id: daToast,
            });
            setTimeout(function () {
                navigate(0);
            }, 2000);
        }
    }

    async function getLokomotivaName(id) {
        const { data, error } = await supabase
            .from('lokomotivy')
            .select('nazev')
            .eq('id', id)

        if (error) {
            console.log(error);
        }

        setLokName(data[0].nazev);
    }

    return (
        <>
            <div style={{maxWidth: "800px", marginTop: "25px"}} className={"admin-dash-tile box-shadow-s center-vertical center-horizontal flex-column justify-c-center" + (rez.configured ? "" : " admin-dash-tile-warning")}>
                <h2>{rez.name}</h2>
                {rez.configured ?
                    <div className={'flex-row justify-c-center gap-m'}>
                        <div className={'center-vertical flex-column'}>
                            <p>Zarezerováno</p>
                            <p><b>8</b> z <b>{rez.available_seats}</b></p>
                        </div>
                        <div className={'center-vertical flex-column'}>
                            <p>Lokomotiva</p>
                            <b>{lokName}</b>
                        </div>
                        <div className={'center-vertical flex-column'}>
                            <p>Vagóny</p>
                            <b>{rez.carriages.length}</b>
                        </div>
                    </div>
                    :
                    <p>Vyžaduje konfiguraci</p>
                }
                <div className="flex-row justify-c-center" style={{marginTop: "20px"}}>
                    <Link to={"upravit/" + rez.id} className={'admin-dash-button'}>
                        {rez.configured ? "Upravit" : "Konfigurovat"}
                    </Link>
                    <p className={'admin-dash-button'} onClick={(e) => {
                        void askToDelete();
                    }} >
                        Smazat
                    </p>
                </div>
            </div>
        </>
    );

}