import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const ModalRezSys = ({ params, close, fin }) => {

    console.log(params)

    return (
        <>
            <div className={'modal-top flex-row justify-c-space-between'}>
                <h1>Dokončit úpravu rezervace?</h1>
                <FontAwesomeIcon icon={faTimesCircle} className={'pointer'} onClick={close} />
            </div>
            <div className={'modal-bottom flex-column'}>
                <div className={'modal-text'}>
                    <p>
                        Vybraná lokomtiva je <b>{params.lokomotiva[1]}</b> v konfiguraci s vagóny
                        {
                            params.vagony.map((stroj, i, array) => {

                                stroj = stroj.split(",")[1]

                                if (i + 1 === array.length) {
                                    return <span> a<b> {stroj}</b>.</span>
                                } else if  (i === 0) {
                                    return <b> {stroj}</b>
                                } else {
                                    return <span>, <b>{stroj}</b></span>
                                }
                            })
                        }
                    </p>
                                    <p className={'flex-column gap-s'}>
                                        <h3>Ceník</h3>
                                        <div className={'center-vertical flex-row gap-s'}>
                                            <div className={'center-vertical flex-column admin-dash-tile'}>
                                                <b>Základní jízdenka</b>
                                                <p>{params.price.base} kč</p>
                                            </div>
                                            <div className={'center-vertical flex-column admin-dash-tile'}>
                                                <b>Dětská jízdenka</b>
                                                <p>{params.price.child} kč</p>
                                            </div>
                                            <div className={'center-vertical flex-column admin-dash-tile'}>
                                                <b>Zvýhodněná jízdenka</b>
                                                <p>{params.price.discounted} kč</p>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                                    <div
                                        className={'modal-image'}
                                        style={{
                                            backgroundImage: 'url(' + params.obrazek + ')',
                                            backgroundPositionY: params.pos + '%'
                                        }}
                                    >
                                        //TODO: Visualizace soupravy
                                    </div>
                                    <b className={'admin-dash-button text-center no-margin no-radius'}
                                        style={{transform: "translateY(0px)"}} onClick={() => {
                                        fin();
                                    }}>
                                        Uložit
                                    </b>
                                </div>
                                </>
                                )
                                    ;
                                }

                                export default ModalRezSys;