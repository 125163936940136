import React from "react";
import Navbar from "./komponenty/nav/Navbar";
import AnimatedRoutes from "./komponenty/utils/animatedRoutes";
import {BrowserRouter as Router} from "react-router-dom";
import useScreenSize from "./komponenty/utils/useScreenSize";
import SmallNav from "./komponenty/nav/SmallNav";
import Footer from "./komponenty/footer/Footer";
import ScrollToTop from "./komponenty/utils/scrollToTop";
import {Toaster} from "react-hot-toast";
import {SpeedInsights} from "@vercel/speed-insights/react";

function App() {

    const {width} = useScreenSize();

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar-main").setAttribute("style", "top: 0px; opacity: 1")
            document.getElementById("navbar-small").setAttribute("style", "top: -30px;")
        } else if( width >= 750 ) {
            document.getElementById("navbar-main").setAttribute("style", "top: -66px; opacity: 0.5")
            document.getElementById("navbar-small").setAttribute("style", "top: 0px;")
        }
        prevScrollpos = currentScrollPos;
    }

    return (
        <>
            <Router>
                <ScrollToTop>
                    <SmallNav />
                    <Navbar />
                    <AnimatedRoutes />
                    <Footer />
                    <Toaster />
                </ScrollToTop>
            </Router>
            <SpeedInsights/>
        </>
    );
}

export default App;
