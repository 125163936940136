import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {AnimatePresence, motion} from "framer-motion";
import Capsule from "../../komponenty/blog/Capsule";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../../komponenty/utils/supabase";
import toast from "react-hot-toast";
import Gondola from "../../komponenty/o-nas/Gondola";
import ModalShell from "../../komponenty/modals/ModalShell";

function NovyStroj() {

    const navigate = useNavigate();

    const [nadpis, setNadpis] = useState('T-XXXX');
    const [popis, setPopis] = useState('Voluptas voluptate el eveniet. Blanditiis distinctio est vitae aliquam tempora et ullam. Aut architecto aut voluptate est corporis nihil molestiae. Beatae atque ea quia corporis qui. Quis voluptatem pariatur nobis porro eaque. Neque eos nihil placeat consequuntur iste.');
    const [type, setType] = useState('demo');
    const [imagePos, setImagePos] = useState(50);
    const [imageLink, setImageLink] = useState('/img/bg.jpg');
    const [image, setImage] = useState();

    const [modalOpen, setModalOpen] = useState(false);

    const closeModal = () => {
        document.body.style.overflowY = 'initial';
        setModalOpen(false)
    };
    const openModal = () => {
        document.body.style.overflowY = 'hidden';
        setModalOpen(true)
    };

    let saveToast;

    // noinspection JSCheckFunctionSignatures
    async function readImage(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();reader.onloadend = async function () {
                setImageLink(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function saveImage(input) {
        if (input.files && input.files[0]) {
            setImage(input.files[0]);
        }
    }

    async function saveArticle() {

        if (
            nadpis === 'T-XXXX' ||
            popis.includes('Blanditiis distinctio  est vitae ') ||
            image == null ||
            type === 'demo'
        ) {
            toast.error(() => (
                <span>
                    Všechna pole <b>musí</b> být vyplněna!
                </span>
            ));
            return;
        }

        saveToast = toast.loading('Ukládání...')

        const { data, error } = await supabase
            .from(type)
            .insert({ nazev: nadpis, popis: popis })
            .select('id')

        if (error) {
            toast.error('Uložení selhalo', {
                id: saveToast
            });
            console.log(error);
        } else {
            void uploadFile(data[0].id);
        }
    }

    async function uploadFile(id) {
        const { data, error } = await supabase.storage
            .from('obrazky')
            .upload(id + type + '_' + image.name.replace(' ', '_'), image, {
                cacheControl: '604800'
            })

        if (error) {
            toast.error('Uložení médií selhalo', {
                id: saveToast
            });
            console.log(error);
        } else {
            void getImageUrl(id, data.path);
        }
    }

    async function getImageUrl(id, path) {
        const { data } = supabase
            .storage
            .from('obrazky')
            .getPublicUrl(path)

        void changeCoverImage(id, data.publicUrl);
    }

    async function changeCoverImage(id, path) {
        const { error } = await supabase
            .from(type)
            .update({ obrazek: path })
            .eq('id', id)

        if (error) {
            toast.error('Aktualizace strojů selhala', {
                id: saveToast
            });
            console.log(error);
        } else {
            toast.success('Stroj byl uložen!', {
                id: saveToast
            });

            setTimeout(function(){
                navigate('..')
            }, 1000);
        }

    }

    return (
        <>
            <Helmet>
                <title>Přidat Stroj | Posázavský Pacifik</title>
            </Helmet>
            <AnimatePresence
                initial={false}
                mode={'wait'}
            >
                {modalOpen && <ModalShell modalOpen={modalOpen} close={closeModal} data={{
                    popis: popis, nazev: nadpis, obrazek: imageLink, id: 0, pos: imagePos
                }} type={1} />}
            </AnimatePresence>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page admin-akce-new'}>

                    <div className={'aan-fields flex-column'}>

                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Konfigurace</h2>
                            <textarea placeholder={'Nadpis'} onChange={(e) => {
                                setNadpis(e.target.value);
                            }}/>
                            <textarea placeholder={'Popis'} onChange={(e) => {
                                setPopis(e.target.value);
                            }}/>
                            <select onChange={(e) => {
                                setType(e.target.value);
                                console.log(e.target.value)
                            }}>
                                <option value={'demo'} selected={true} disabled>Typ stroje</option>
                                <option value={'lokomotivy'}>Lokomotiva</option>
                                <option value={'vagony'}>Vagón</option>
                            </select>
                        </div>
                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Média</h2>
                            <input type={"file"} accept={'image/png,image/jpeg,image/webp'} onChange={(e) => {
                                void readImage(e.target);
                                saveImage(e.target);
                            }}/>
                            <input type={'range'} min={1} max={100} value={imagePos} onChange={(e) => {
                                setImagePos(parseInt(e.target.value));
                            }}/>
                        </div>
                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Správa</h2>
                            <div className={'flex-row'}>
                                <p className={'admin-dash-button text-center'} onClick={saveArticle}>
                                    Uložit
                                </p>
                                <Link to={'/admin/stroje'} className={'admin-dash-button text-center'}>
                                    Zrušit
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className={'asn-preview-widget flex-column center-vertical '}>
                        <h1 className={'text-center'}>Náhled</h1>
                        <Gondola params={{
                            popis: popis, nazev: nadpis, obrazek: imageLink, id: 0
                        }} type={'demo'} openModal={openModal} closeModal={closeModal} modalState={modalOpen} />
                    </div>
                </div>

            </motion.div>
        </>
    )
}

export default NovyStroj;