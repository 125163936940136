import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {supabase} from "../komponenty/utils/supabase";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";

function RezervaceMain() {

    const params = useParams();

    let [info, setInfo] = useState([]);

    useEffect(() => {
        void findId();
    }, [])

    const findId = async () => {

        let id = params.rezervace_id.split('.')

        const { data } = await supabase
            .from('rezervace_system')
            .select()
            .eq('id', id[id.length - 1]);

        if (data.length !== 0) {
            setInfo(data[0]);
        } else {
            //TODO: Render a 404 page
        }
    }

    return (
        <>
            <Helmet>
                <title>Rezervace | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        className={'padding-page-top'}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >
			
			<h1>{info.name}</h1>

            </motion.div>
        </>
    );
}

export default RezervaceMain;