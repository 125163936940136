import React, {useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import Capsule from "../../komponenty/blog/Capsule";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../../komponenty/utils/supabase";
import toast from "react-hot-toast";

function NovaAktualita() {

    const navigate = useNavigate();

    const [nadpis, setNadpis] = useState('');
    const [popis, setPopis] = useState('');
    const [obsah, setObsah] = useState('');
    const [datum, setDatum] = useState('');
    const [imageLink, setImageLink] = useState('');
    const [imagePos, setImagePos] = useState(50);
    const [image, setImage] = useState();
    const [fotogalerie, setFotogalerie] = useState('');

    let saveToast;

    useEffect(() => {
        setNadpis('Nadpis');
        setPopis('Voluptas voluptate el eveniet. Blanditiis distinctio est vitae aliquam tempora et ullam. Aut architecto aut voluptate est corporis nihil molestiae. Beatae atque ea quia corporis qui. Quis voluptatem pariatur nobis porro eaque. Neque eos nihil placeat consequuntur iste.');
        setDatum('29.8.2023')
        setImageLink('/img/bg.jpg')
    }, [])

    // noinspection JSCheckFunctionSignatures
    async function readImage(input) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();reader.onloadend = async function () {
                setImageLink(reader.result);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }

    function saveImage(input) {
        if (input.files && input.files[0]) {
            setImage(input.files[0]);
        }
    }

    async function saveArticle() {

        let obsah1 = obsah;

        if (
            nadpis === 'Nadpis' ||
            popis.includes('Blanditiis distinctio  est vitae ') ||
            obsah1 === '' ||
            image == null
        ) {
            toast.error(() => (
                <span>
                    Všechna pole <b>musí</b> být vyplněna!
                </span>
            ));
            return;
        }

        saveToast = toast.loading('Ukládání...')

        const { data, error } = await supabase
            .from('aktuality')
            .insert({ datum: datum, nadpis: nadpis, popis: popis, obsah: obsah1, pos: imagePos, bonus_img: fotogalerie})
            .select('id')

        if (error) {
            toast.error('Uložení selhalo', {
                id: saveToast
            });
            console.log(error);
        } else {
            void uploadFile(data[0].id);
        }
    }

    async function uploadFile(id) {
        const { data, error } = await supabase.storage
            .from('obrazky')
            .upload(id + '_aktuality_' + image.name.replace(' ', '_'), image, {
                cacheControl: '604800'
            })

        if (error) {
            toast.error('Uložení médií selhalo', {
                id: saveToast
            });
            console.log(error);
        } else {
            console.log(data.path);
            void getImageUrl(id, data.path);
        }
    }

    async function getImageUrl(id, path) {
        const { data } = supabase
            .storage
            .from('obrazky')
            .getPublicUrl(path)

        void changeArticleImage(id, data.publicUrl);
    }

    async function changeArticleImage(id, path) {
        const { error } = await supabase
            .from('aktuality')
            .update({ img: path })
            .eq('id', id)

        if (error) {
            toast.error('Aktualizace aktualit selhala', {
                id: saveToast
            });
            console.log(error);
        } else {
            toast.success('Aktualita byla uložena!', {
                id: saveToast
            });

            setTimeout(function(){
                navigate('..')
            }, 1000);
        }

    }

    return (
        <>
            <Helmet>
                <title>Přidat Aktualitu | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page admin-akce-new'}>

                    <div className={'aan-fields flex-column'}>

                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Konfigurace</h2>
                            <textarea placeholder={'Nadpis'} onChange={(e) => {
                                setNadpis(e.target.value);
                            }}/>
                            <textarea placeholder={'Popis'} onChange={(e) => {
                                setPopis(e.target.value);
                            }}/>
                            <textarea placeholder={'Obsah'} onChange={(e) => {
                                setObsah(e.target.value);
                            }}/>
                            <input type={'text'} placeholder={'Datum (Např. 29.8.2023)'} onChange={(e) => {
                                setDatum(e.target.value);
                            }}/>
                        </div>
                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Média</h2>
                            <input type={"file"} accept={'image/png,image/jpeg,image/webp'} onChange={(e) => {
                                void readImage(e.target);
                                saveImage(e.target);
                            }}/>
                            <input type={'range'} min={1} max={100} value={imagePos} onChange={(e) => {
                                setImagePos(parseInt(e.target.value));
                            }}/>
                            <br/>
                            <h4 className={'margin-s'}>Fotogalerie</h4>
                            <textarea placeholder={'Odkazy separovat pomocí ","'} onChange={(e) => {
                                setFotogalerie(e.target.value);
                            }}/>
                        </div>
                        <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                            <h2>Správa</h2>
                            <div className={'flex-row'}>
                                <p className={'admin-dash-button text-center'} onClick={saveArticle}>
                                    Uložit
                                </p>
                                <Link to={'/admin/akce'} className={'admin-dash-button text-center'}>
                                    Zrušit
                                </Link>
                            </div>
                        </div>

                    </div>
                    <div className={'aan-preview-widget'}>
                        <h1 className={'text-center'}>Náhled</h1>
                        <Capsule data={{
                            popis: popis, nadpis: nadpis, datum: datum, img: imageLink
                        }} demo={true} />
                    </div>
                    <div className={'aan-preview-full'}>
                        <div className={'blog-read-header flex-column center-vertical'}>
                            <img src={imageLink}
                                 className={'blog-read-image'}
                                 style={{objectPosition: `0 ${imagePos}%`}}
                                 alt={'Obrázek akce'}
                            />
                            <h1 className={'text-header text-center'} style={{fontSize: '27px', fontWeight: 600}}>{nadpis}</h1>
                        </div>

                        <div className={'blog-read-content flex-column center-vertical'}>
                            <div className={'flex-row blog-read-top'}>
                                <p  className={'text-muted text-left blog-read-subtitle'}>{popis}</p>
                                <p  className={'text-right'}>Datum přidání <b>{datum}</b></p>
                            </div>
                            <div className={'flex-column center-vertical'}>
                                <p className={"text-justify"}>{obsah}</p>
                            </div>
                        </div>
                    </div>

                </div>

            </motion.div>
        </>
    )
}

export default NovaAktualita;