import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {motion} from "framer-motion";
import {supabase} from "../../komponenty/utils/supabase";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";

function Admin() {

    const navigate = useNavigate();

    const [user, setUser] = useState([])

    const [statAktuality, setStatAktuality] = useState(0);
    const [statAkce, setStatAkce] = useState(0);
    const [statRez, setStatRez] = useState(0);
    const [statStroje, setStatStroje] = useState(0);

    //const [users, setUsers] = useState([]);

    let logoutToast;

    useEffect(() => {
        supabase.auth.getSession().then(({data: {session}}) => {
                // noinspection JSCheckFunctionSignatures
                setUser(session.user);
        })

        void statsAkce();
        void statsStroje();
        void statsAktuality();
        void rezervaceStatus();
        //void getUsers();

    }, []);

    async function statsAkce() {
        const { data, error } = await supabase
            .from('akce')
            .select('id');

        if (error) {
            console.log(error)
        } else {
            setStatAkce(data.length);
        }
    }

    async function statsAktuality() {
        const { data, error } = await supabase
            .from('aktuality')
            .select('id');

        if (error) {
            console.log(error)
        } else {
            setStatAktuality(data.length);
        }
    }

    async function statsStroje() {
        setStatStroje(await getLokomotivyCount() + await getVagonyCount())
    }

    async function getLokomotivyCount() {
        const { data, error } = await supabase
            .from('lokomotivy')
            .select('id');

        if (error) {
            console.log(error);
            return 0;
        } else {
            return data.length;
        }
    }

    async function getVagonyCount() {
        const { data, error } = await supabase
            .from('vagony')
            .select('id');

        if (error) {
            console.log(error);
            return 0;
        } else {
            return data.length;
        }
    }

    async function rezervaceStatus() {
        const { data, error } = await supabase
            .from('rezervace_system')
            .select()
            .eq('configured', 'FALSE')

        if (error) {
            console.log(error);
        } else {
            setStatRez(data.length);
        }
    }

    /*async function getUsers() {
        const { data: { users }, error } = await supabase.auth.admin.listUsers()
        if (error) {
            console.log(error)
        } else {
            console.log(users);
        }
    }

     */

    async function askToSignOut() {
        logoutToast = toast((t) => (
            <span className={'flex-column center-vertical'}>
                Opravdu se chcete odhlásit?
                <div className={'flex-row center-horizontal margin-s'}>
                    <p className={'admin-dash-button'} onClick={signOut}>
                        Ano
                    </p>
                    <p className={'admin-dash-button'} onClick={() => toast.dismiss(t.id)}>
                        Ne
                    </p>
                </div>
            </span>
        ), {
            duration: 6000,
        });
    }
    async function signOut() {

        toast.loading('Odhlašování...', {
            id: logoutToast,
        });

        const { error } = await supabase.auth.signOut();
        if (error) {
            toast.error('Vyskytl se error', {
                id: logoutToast,
            });
            console.log(error);
        } else {
            toast.success('Úspěšně odhlášeno!', {
                id: logoutToast,
            });

            navigate('/');

            setTimeout(function () {
                toast.dismiss(logoutToast);
            }, 2000);
        }

    }

    return (
        <>
            <Helmet>
                <title>Admin | Posázavský Pacifik</title>
            </Helmet>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <div className={'admin-dash flex-column center-vertical center-horizontal'}>

                    <div className={'admin-dash-grid'}>
                        <div className={'admin-dash-column flex-column'}>
                            <div
                                className={'admin-dash-tile box-shadow-s flex-column center-horizontal center-vertical'}>
                                <h2>Aktuální uživatel</h2>
                                <div className={'admin-dash-tile-user flex-row center-vertical center-horizontal'}>
                                    <p className={'admin-dash-icon-margin text-center'}>{user.email}</p>
                                    <FontAwesomeIcon onClick={askToSignOut} icon={faSignOut}/>
                                </div>
                            </div>
                            {statRez !== 0 ? <div
                                className={'admin-dash-tile admin-dash-tile-warning box-shadow-s flex-column center-horizontal center-vertical'}>
                                <h2>Nedokončená konfigurace</h2>
                                <p>Aktuálně je nedokončeno <b>{statRez}</b> rezervačních stránek.</p>
                                <Link to={'rezervace'} className={'admin-dash-button text-center margin-top-s'}>
                                    Zobrazit
                                </Link>
                            </div> 
                            : 
                            <div
                                className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Rezervace</h2>
                                <div className={'flex-row'}>
                                    <Link to={'rezervace'} className={'admin-dash-button text-center'}>
                                        Spravovat
                                    </Link>
                                </div>
                            </div>
                            }
                            <div
                                className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Akce</h2>
                                <div className={'flex-row'}>
                                    <Link to={'akce/nova'} className={'admin-dash-button text-center'}>
                                        Přidat
                                    </Link>

                                    <Link to={'akce'} className={'admin-dash-button text-center'}>
                                        Spravovat
                                    </Link>
                                </div>
                            </div>
                            <div
                                className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Aktuality</h2>
                                <div className={'flex-row'}>
                                    <Link to={'aktuality/nova'} className={'admin-dash-button text-center'}>
                                        Vytvořit
                                    </Link>
                                    <Link to={'aktuality'} className={'admin-dash-button text-center'}>
                                        Spravovat
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className={'admin-dash-column flex-column'}>
                            <div
                                className={'admin-dash-tile box-shadow-s flex-column center-horizontal center-vertical'}>
                                <h2>Statistiky</h2>
                                <div className={'admin-dash-tile-stats flex-row justify-c-space-evenly'}>
                                    <div className={'flex-column center-vertical'}>
                                        <h1>{statAkce}</h1>
                                        <p>Akcí</p>
                                    </div>
                                    <div className={'flex-column center-vertical'}>
                                        <h1>{statAktuality}</h1>
                                        <p>Aktualit</p>
                                    </div>
                                    <div className={'flex-column center-vertical'}>
                                        <h1>{statStroje}</h1>
                                        <p>Strojů</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Stroje</h2>
                                <div className={'flex-row'}>
                                    <Link to={'stroje/novy'} className={'admin-dash-button text-center'}>
                                        Přidat
                                    </Link>

                                    <Link to={'stroje'} className={'admin-dash-button text-center'}>
                                        Spravovat
                                    </Link>
                                </div>
                            </div>
                            <div
                                className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Obrázky</h2>
                                <div className={'flex-row'}>
                                    <Link to={'obrazky/nahrat'} className={'admin-dash-button text-center'}>
                                        Nahrát
                                    </Link>

                                    <Link to={'obrazky'} className={'admin-dash-button text-center'}>
                                        Spravovat
                                    </Link>
                                </div>
                            </div>
                            {/*<div className={'admin-dash-tile box-shadow-s  flex-column center-horizontal center-vertical'}>
                                <h2>Správa uživatelů</h2>
                                <div className={'flex-column admin-dash-user-manager'}>

                                    {users.map((data, i) => (
                                        <UserManager data={data} colour={ i % 2 } />
                                    ))}

                                    <div className={'flex-row center-vertical'}>
                                        <p className={'admin-dash-icon-margin'}>t.pryl@seznam.cz</p>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <div className={'flex-row center-vertical'}>
                                        <p className={'admin-dash-icon-margin'}>t.pryl@seznam.cz</p>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <div className={'flex-row center-vertical'}>
                                        <p className={'admin-dash-icon-margin'}>t.pryl@seznam.cz</p>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <div className={'flex-row center-vertical'}>
                                        <p className={'admin-dash-icon-margin'}>t.pryl@seznam.cz</p>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    <div className={'flex-row center-vertical'}>
                                        <p className={'admin-dash-icon-margin'}>t.pryl@seznam.cz</p>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>

                </div>

            </motion.div>
        </>
    )
}

export default Admin;