import {motion} from "framer-motion";
import ModalOnas from "./ModalOnas";
import ModalRezSys from "./ModalRezSys";
import React, {useEffect, useState} from "react";

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
    },
};



const ModalShell = ({ close, data, type, fin }) => {

    const [modalInside, setModalInside] = useState(``);

    useEffect(() => {
        select();
    }, []);

    async function select() {
        switch (type) {
            case 1:
                setModalInside(<ModalOnas params={data} close={close}/>);
                break;
            case 2:
                setModalInside(<ModalRezSys params={data} close={close} fin={fin}/>);
                break;
            default:
                break;
        }
    }

    return (
        <motion.div
            onClick={close}
            className='modal-backdrop flex-row center-vertical center-horizontal'
            style={{ top: window.scrollY }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal-shell flex-column justify-c-space-between box-shadow-l"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                {modalInside}
            </motion.div>
        </motion.div>
    );
}

export default ModalShell;