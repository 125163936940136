import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {AnimatePresence, motion} from "framer-motion";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {supabase} from "../../komponenty/utils/supabase";
import ModalShell from "../../komponenty/modals/ModalShell";
import toast from "react-hot-toast";

function UpravitRezervace() {

    const navigate = useNavigate();
    const params = useParams();
    const id = params.rezervace_id;

    const [lokomotivy, setLokomotivy] = useState([]);
    const [vagony, setVagony] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const [agePricing] = useState({base: 0, child: 0, discounted: 0});
    const [lokomotiva, setLokomotiva] = useState([1, "T478.2006 – Zamračená"]);
    const [vagonyList, setVagonyList] = useState([]);


    let saveToast;

    useEffect(() => {
        fetchData("lokomotivy");
        fetchData("vagony");
    }, []);

    async function fetchData(what) {
        const { data, error } = await supabase
            .from(what)
            .select("nazev, id")
            .order("id")

        if (error){
            console.log(error);
        } else {
            switch (what) {
                case "lokomotivy": {
                    setLokomotivy(data);
                    break;
                }
                case "vagony": {
                    setVagony(data)
                    break;
                }

                default: {
                    console.log("Invalid DB")
                }
            }
        }
    }

    const closeModal = () => {
        document.body.style.overflowY = 'initial';
        setModalOpen(false)
    };
    const openModal = (data) => {
        document.body.style.overflowY = 'hidden';
        setModalOpen(true)
        setModalData(data);
    };

    function compileModalData() {
        let res = {};

        res.lokomotiva = lokomotiva;
        res.vagony = vagonyList;
        res.price = {base: agePricing.base, child: agePricing.child, discounted: agePricing.discounted};

        return res;
    }

    async function save() {
        if (agePricing.base === 0 || agePricing.child === 0 || agePricing.discounted === 0 || vagonyList.length === 0) {
            toast.error(() => (
                <span>
                    Všechny parametry <b>musí</b> být nastaveny!
                </span>
            ));
            return;
        }

        saveToast = toast.loading('Ukládání...');

        let carriages = [];
        let seats = 0;

        for (const vagon of vagonyList) {
            let id = vagon.split(",")[0];
            
            carriages.push(id);
            seats += await calculate_seats(id);
        }

        const { error } = await supabase
                    .from('rezervace_system')
                    .update({ locomotive_id: lokomotiva[0], carriages: carriages, available_seats: seats, pricing_tickets: agePricing, configured: true })
                    .eq('id', id)


        if (error) {
            toast.error('Nastala chyba při ukládání', {
                id: saveToast
            });
            console.log(error);
        } else {
            fin();
        }
    }

    async function calculate_seats(id) {
        const { data, error } = await supabase
            .from('vagony')
            .select('pocet_mist')
            .eq('id', id)

        if (error) {
            toast.error('Nastala chyba při výpočtu míst', {
                id: saveToast
            });
        } else {
            return data[0].pocet_mist;
        }
    }

    async function fin() {
        toast.success('Rezervace byla uložena!', {
            id: saveToast
        });

        setTimeout(function(){
            navigate('..')
        }, 1000);
    }

    return (
        <>
            <Helmet>
                <title>Upravit rezervaci {id} | Posázavský Pacifik</title>
            </Helmet>
            <AnimatePresence
                initial={false}
                mode={'wait'}
            >
                {modalOpen && <ModalShell modalOpen={modalOpen} close={closeModal} data={modalData} type={2} fin={save} />}
            </AnimatePresence>
            <motion.div id={"page-container"}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
                        exit={{opacity: 0}}
                        transition={{duration: .25, ease: 'easeOut'}}
            >

                <Link to={'..'} className={'admin-back flex-row'}>
                    <p className={'text-uppercase text-center'}>
                        <FontAwesomeIcon icon={faAngleLeft} /> Zpět
                    </p>
                </Link>

                <div className={'admin-page aru flex-column center-vertical'}>
                    <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                        <h2>Lokomotiva</h2>
                        <select onChange={(e) => {
                            setLokomotiva(e.target.value);
                        }}>
                            {lokomotivy.map((stroj) => (
                                <option value={[stroj.id, stroj.nazev]} key={stroj.id}>{stroj.nazev}</option>
                            ))}
                        </select>
                    </div>
                    <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                        <h2>Vagóny</h2>
                        <div className={"flex-row gap-s"}>
                            {vagony.map((stroj) => (
                                <button value={[stroj.id, stroj.nazev]} key={stroj.id} onClick={(e) => {
                                    setVagonyList([...vagonyList, e.target.value]);
                                }}>
                                    {stroj.nazev}
                                </button>
                            ))}
                        </div>
                        {
                            vagonyList.map((vagon) => (
                                <p>{vagon.split(",")[1]}</p> //TODO: Udělat odstranitelné
                            ))
                            //TODO: Když se na něco v dropdownu klikne, tak přidat do nějakého listu, kde jde zase odstranit
                        }
                    </div>
                    {/*<div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>*/}
                    {/*    <h2>Trasa</h2>*/}
                    {/*    //TODO: Asi konfigurátor trasy protože jak jinak mám přijít na to kam jedou (tohle bude pain)*/}
                    {/*    //TODO: Možná opomenout, protože pak bych musel blokovat lístky i na úseky a fuck that*/}
                    {/*</div>*/}
                    <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                        <h2>Jízdenky</h2>
                        <div className={'flex-row gap-s'}>
                            <input type={'number'} placeholder={'Základní v kč'} onChange={(e) => {
                                agePricing.base = e.target.value;
                            }}/>
                            <p>kč</p>
                        </div>
                        <div className={'flex-row gap-s'}>
                            <input type={'number'} placeholder={'Dětská v kč'} onChange={(e) => {
                                agePricing.child = e.target.value;
                            }}/>
                            <p>kč</p>
                        </div>
                        <div className={'flex-row gap-s'}>
                            <input type={'number'} placeholder={'Zvýhodněná v kč'} onChange={(e) => {
                                agePricing.discounted = e.target.value;
                            }}/>
                            <p>kč</p>
                        </div>
                        {/*//TODO: Asi konfigurace ceny lístku na základě kam až jedou (pomoc)*/}
                    </div>
                    <div className={'admin-dash-tile box-shadow-s flex-column center-vertical center-horizontal'}>
                        <h2>Správa</h2>
                        <div className={'flex-row'}>
                            <p className={'admin-dash-button text-center'} onClick={() => {
                                openModal(compileModalData);
                            }}>
                                Dokončit
                            </p>
                            <Link to={'..'} className={'admin-dash-button text-center'}>
                                Zrušit
                            </Link>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default UpravitRezervace;